import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { ColorPicker } from 'components/ui-components-cape/ColorPicker';
import { ColorHelpers } from 'helpers/colors.helpers';
import ColorSelectorPredefined from './color-selector-predefined';
import ColorSelectorPredefinedCustom from './color-selector-predefined-custom';
import ColorSelectorCompact from './color-selector-compact';
import '../styles/main.scss';

/**
 * ColorSelector
 * Allows you to select one of the colors
 */
export class ColorSelector extends Component {
    static propTypes = {
        /** Array with colors to display in the picker. When type is 'predefined' it'll be the only choices, otherwise it'll be the swatch underneath the picker. */
        colors: PropTypes.arrayOf(PropTypes.string),
        /** Color to set as selected when no value is added */
        defaultColor: PropTypes.string,
        /** Function which gets called when the selected color has changed */
        onMutation: PropTypes.func,
        /** Type of picker to display */
        picker: PropTypes.oneOf(['predefined', 'picker', 'predefinedCustom', 'compact']),
        /** type picker can have a pipette if usePipette is true and supported in the browser */
        usePipette: PropTypes.bool,
        /** Current value of the picker */
        value: PropTypes.any,
        /** If the color picker should set the first option if there is no value */
        setFirstOption: PropTypes.bool
    };

    static defaultProps = {
        defaultColor: '#000000',
        outputFormat: 'hex',
        onMutation: () => {},
        picker: 'predefinedCustom',
        colors: [],
        setFirstOption: false
    };

    constructor(props) {
        super(props);

        this.state = {
            value: this.getColorValue()
        };
    }

    /** Set first option if there is not value and setFirstOption is true */
    componentDidMount() {
        const { onMutation, setFirstOption, value, colors } = this.props;
        if (setFirstOption && !value) {
            this.setState({ value: colors[0] });
            const cssColor = ColorHelpers.getCssColor(colors[0]);

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => onMutation(cssColor), 250);
            this.props.onMutation(colors[0]);
        }
    }

    /**
     * Compare current and new color.
     * If they are different, update state.
     */
    componentDidUpdate() {
        const currentColor = this.state.value;
        const newColor = this.getColorValue();
        if (!isEqual(currentColor, newColor)) {
            this.setState({ value: newColor });
        }
    }

    /**
     * Get the color and create a color picker object.
     * @returns {object} - Color picker object.
     */
    getColorValue() {
        const value = (() => {
            if (this.props.value) {
                return this.props.value;
            }

            return this.props.defaultColor;
        })();

        return ColorHelpers.getColorObjectFromString(value);
    }
    /**
     * On change item
     * Sets the parent using onMutation
     */
    onChangeCustom = (color) => {
        const { onMutation } = this.props;
        this.setState({ value: color });
        const cssColor = ColorHelpers.getCssColor(color).toLowerCase();

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => onMutation(cssColor), 250);
    };

    onChangePredefined = (color) => {
        const { onMutation } = this.props;

        onMutation(color.hex);
        this.setState({
            value: {
                ...this.state.value,
                points: [
                    {
                        location: 0,
                        color: {
                            hex: color.hex,
                            rgb: color.rgb
                        }
                    }
                ]
            }
        });
    };

    rgbaToHex = (rgba) => {
        const r = rgba.r.toString(16).padStart(2, '0');
        const g = rgba.g.toString(16).padStart(2, '0');
        const b = rgba.b.toString(16).padStart(2, '0');
        const a = Math.round(rgba.a * 255)
            .toString(16)
            .padStart(2, '0');
        return `#${r}${g}${b}${a}`;
    };

    onChangeCompact = (color) => {
        const { onMutation, useAlpha } = this.props;

        let newColor = color.hex;
        if (useAlpha) {
            newColor = this.rgbaToHex(color.rgb);
        }
        onMutation(newColor);

        this.setState({
            value: {
                ...this.state.value,
                points: [
                    {
                        location: 0,
                        color: {
                            hex: color.hex,
                            rgb: color.rgb
                        }
                    }
                ]
            }
        });
    };

    /**
     * On change item for the customized picker
     */
    onChangePredefinedCustom = (color) => {
        const { onMutation } = this.props;

        const cssColor = ColorHelpers.getCssColor(color);
        onMutation(cssColor);
        this.setState({ value: color });
    };

    render() {
        const { picker, colors, useGradients, useAlpha, usePipette } = this.props;
        const { value } = this.state;

        // Predefined color set
        if (picker === 'predefined') {
            return <ColorSelectorPredefined colors={colors} onChange={this.onChangePredefined} value={value.points[0].color.hex} className="color-selector" />;
        }

        // Custom styled predefined color set
        if (picker === 'predefinedCustom') {
            return <ColorSelectorPredefinedCustom colors={colors} onChange={this.onChangePredefinedCustom} value={value} className="color-selector" />;
        }

        // Twitter picker
        if (picker === 'compact') {
            return <ColorSelectorCompact colors={colors} useAlpha={useAlpha} onChange={this.onChangeCompact} value={value} className="color-selector" />;
        }

        // All colors
        return (
            <ColorPicker color={value} usePipette={usePipette} useGradients={useGradients} useAlpha={useAlpha} onChange={this.onChangeCustom} size="medium" />
        );
    }
}

export default ColorSelector;
